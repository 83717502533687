import { graphql } from "gatsby"
import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import ApparentProblemSvg from "../assets/problem-solving/apparent-problem.svg"
import TooAbstractSvg from "../assets/problem-solving/too-abstract.svg"
import NeedVerbSvg from "../assets/problem-solving/need-verb.svg"
import InsightOverObservationSvg from "../assets/problem-solving/insight-over-observation.svg"

const ProblemSolvingPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title || "Title"

  return (
    <div className="bg-blueprint-500">
      <Layout className="bg-blueprint-500" location={location} title={siteTitle}>
        <Seo title="Problem Solving" />
        <article
          className="text-center prose md:prose-lg lg:prose-xl max-w-prose md:max-w-2xl lg:max-w-4xl prose-headings:text-amber-50 prose-a:text-amber-50 prose-blockquote:text-amber-100 prose-strong:text-amber-50 text-amber-50 m-2 sm:m-auto"
          itemScope
          itemType="http://schema.org/Article"
        >
          <h2>Problem Solving</h2>
          <p>
            Solving problems has evolved as our technology has changed. It's now
            more important than ever to solve the right problem in the right
            way.
          </p>

          <p>
            Below are a set of common methods I use to solve problems that can
            be generalized for many types of problems across a variety of
            disciplines. I just happen to use them for consulting and building
            software.
          </p>
          <p>
            <small>
              The numbers align to the book "101 Things I learned in Product
              Design School" but have been added to with additional notes that
              help to contextualize the understanding.
            </small>
          </p>

          <div className="mb-40" />

          <ApparentProblemSvg />

          <p>
            I love this one. It's too easy to get caught up and focused on the
            problems we can see. Are we fighting the fire we can see or looking
            to try to track down the person starting all of these fires?!
          </p>

          <div className="mb-60" />

          <TooAbstractSvg />

          <p>
            Getting blindsided by new information at the end of some work is
            easy when we jump into solutioning too quickly. To make sure all
            factors are involved and at the right level it might be good to
            check both specifics and abstractions.
          </p>

          <div className="mb-60" />

          <NeedVerbSvg />

          <p>
            This sort of relates to a lot of things but the essence is the same.
            When you're solving a problem for someone be sure that you know
            their problem or what they're trying to do to ensure that they can
            solve their problem.
          </p>

          <p>
            If we don't understand the job they're trying to do, we likely can't
            solve the problem in a satisfactory way.
          </p>

          <p>
            <small>
              <a href="https://medium.com/@stewart/we-dont-sell-saddles-here-4c59524d650d">
                We Don't Sell Saddles Here
              </a>{" "}
              is a reference to the linked memo from Steward Butterfield, CEO of
              Slack.
            </small>
          </p>

          <div className="mb-60" />

          <InsightOverObservationSvg />

          <p>
            When it comes time to share your discoveries with others, don't look
            to be rewarded for observations or awareness. Insights are truly
            what help make decisions.
          </p>

          <p>
            Taking this one step further, I see multiple insights coming
            together to synthesize into concepts or models.
          </p>
        </article>
      </Layout>
    </div>
  )
}

export default ProblemSolvingPage

export const pageQuery = graphql`
  query ProblemSolvingPageQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`
